
// Override default variables before the import of bootstrap
$body-bg: #000;

$content-color: #fff;
$content-bg:    #000;
$brand-primary: #FF5599;
$brand-secondary: #12ABAB;

$font: Dimbo-Regular, sans-serif;


$default-transition-time: 1s / 4 !default;
@mixin transition-all($time: $default-transition-time, $customize_props: false) {
	//transition: all .33s cubic-bezier(.3, 0, 0, 1.3);
	@if $customize_props {
		transition: all $time cubic-bezier(0.425, -0.600, 0.700, 1.520), //cubic-bezier(.42, 0, .58, 1),
					font-size 0s,
					$customize_props;
	} @else {
		transition: all $time cubic-bezier(0.425, -0.600, 0.700, 1.520), //..cubic-bezier(.42, 0, .58, 1),
					font-size 0s;
	}
}

//https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting
@mixin noselect() {
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */
	   -khtml-user-select: none; /* Konqueror HTML */
		 -moz-user-select: none; /* Firefox */
		  -ms-user-select: none; /* Internet Explorer/Edge */
			  user-select: none; /* Non-prefixed version, currently
									supported by Chrome and Opera */
  }
  
  // From https://github.com/sass/sass/issues/183
  $z-index: 0;
  @function next-z-index() {
	  $z-index: $z-index + 1;
	  @return $z-index;
  }
  
  @mixin fullpage() {
	  position: fixed;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  z-index: next-z-index();
	  overflow-y: auto;
  }
  
  @mixin main-card-root() {
	  @include fullpage();
	  z-index: next-z-index();
  
	  display:          flex;
	  justify-content:  center;
	  align-items:      center;
	  transform-origin: top center;
  
	  transition: all .33s cubic-bezier(.3, 0, 0, 1.3);
  }
  
  @mixin force-word-break() {
	  // https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
	  /* These are technically the same, but use both */
	  overflow-wrap: break-word;
	  word-wrap: break-word;
  
	  -ms-word-break: break-all;
	  /* This is the dangerous one in WebKit, as it breaks things wherever */
	  word-break: break-all;
	  /* Instead use this non-standard one: */
	  word-break: break-word;
  
	  /* Adds a hyphen where the word breaks, if supported (No Blink) */
	  -ms-hyphens: auto;
	  -moz-hyphens: auto;
	  -webkit-hyphens: auto;
	  hyphens: auto;
  };
  
  

  $default-transition-time: 1s / 4 !default;
@mixin transition-all($time: $default-transition-time, $customize_props: false) {
	//transition: all .33s cubic-bezier(.3, 0, 0, 1.3);
	@if $customize_props {
		transition: all $time cubic-bezier(0.425, -0.600, 0.700, 1.520), //cubic-bezier(.42, 0, .58, 1),
					font-size 0s,
					$customize_props;
	} @else {
		transition: all $time cubic-bezier(0.425, -0.600, 0.700, 1.520), //..cubic-bezier(.42, 0, .58, 1),
					font-size 0s;
	}
}

@mixin stylize-button($norm, $bg-tx: .65, $br-tx: .85, $dark-perc: 10%) {

	color: transparentize($content-color, .1);
	border-radius: 1rem;
	@include transition-all(.2s);

	// Note must match bootstrap specificity to override without !important
	&:not(:disabled):not(:global(.disabled)):active {
		// background: transparentize($content-bg, .55);
		transform: scale(.95);
	}

	background:   transparentize($norm, $bg-tx);
	border-color: transparentize($norm, $br-tx);

	&:hover, &:focus {
		box-shadow: 0 0 0 0.2rem transparentize($norm, $br-tx - .05);
	}

	&:not(:disabled):not(:global(.disabled)):active {
		$dark: darken($norm, $dark-perc);

		background:   transparentize($dark, $bg-tx);
		border-color: transparentize($dark, $br-tx);
		box-shadow: 0 0 0 0.2rem transparentize($dark, $br-tx - .05);
	}
}
