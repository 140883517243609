/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: .5rem .75rem .5rem 2.625rem;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 1.3rem;
  color: #FFF;
  box-shadow: 3px 3px 15px black;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
									supported by Chrome and Opera */
  transition: all 0.2s cubic-bezier(0.425, -0.6, 0.7, 1.52), font-size 0s; }
  .loginBtn:not(:disabled):not(:global(.disabled)):active {
    transform: scale(0.95); }

.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  height: 100%; }

.loginBtn:focus {
  outline: none; }

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1), 0 0 0.5rem 3px rgba(0, 0, 0, 0.5); }

/* Facebook */
.loginBtn--facebook {
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354C8C; }

.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png") no-repeat;
  background-position: .5rem .75rem; }

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1); }

/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #DD4B39; }

.loginBtn--google:before {
  border-right: #BB3F30 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png") 6px 6px no-repeat; }

.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #E74B37; }

.loginBtn--nofb {
  margin: 1rem;
  padding-left: 0.75rem; }

#welcome-widget {
  --pane-width: 375px;
  opacity: 0;
  width: var(--pane-width);
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%; }
  #welcome-widget .close-ads-button-overlay {
    background: white;
    padding: .25rem .25rem 0rem .5rem;
    position: fixed;
    bottom: 0;
    right: 0;
    border: 1px solid rgba(0, 0, 0, 0.75);
    border-top-left-radius: 1rem;
    border-bottom-width: 0;
    border-right-width: 0;
    filter: drop-shadow(0px 0px 3px #000); }
    #welcome-widget .close-ads-button-overlay svg {
      opacity: 0.75; }
  #welcome-widget .welcome-scroller {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    height: calc(100%); }
    #welcome-widget .welcome-scroller .pane {
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      /* Non-prefixed version, currently
									supported by Chrome and Opera */
      width: var(--pane-width);
      height: 100%;
      flex: 0 0 auto;
      color: white;
      text-shadow: 3px 3px 3px #000;
      background-position: 50% 50%;
      background-size: 200% 200%;
      object-fit: cover;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 3rem; }
      #welcome-widget .welcome-scroller .pane .inner {
        max-width: 576px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        overflow: hidden; }
        #welcome-widget .welcome-scroller .pane .inner .content {
          overflow-y: auto;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: -17px;
          padding-right: 17px;
          mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 1rem, black 90%, rgba(0, 0, 0, 0) 100%);
          -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 1rem, black 90%, rgba(0, 0, 0, 0) 100%); }
          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
            #welcome-widget .welcome-scroller .pane .inner .content {
              right: 0;
              padding-right: 0; } }
      #welcome-widget .welcome-scroller .pane td, #welcome-widget .welcome-scroller .pane th {
        color: white;
        border-color: transparent !important;
        font-weight: normal;
        line-height: 1.1;
        text-align: right; }
      #welcome-widget .welcome-scroller .pane td.person,
      #welcome-widget .welcome-scroller .pane th.person {
        text-align: left;
        cursor: default; }
      #welcome-widget .welcome-scroller .pane thead th {
        background: rgba(255, 255, 255, 0.25); }
      #welcome-widget .welcome-scroller .pane thead th:first-child {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem; }
      #welcome-widget .welcome-scroller .pane thead th:last-child {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem; }
      #welcome-widget .welcome-scroller .pane label {
        cursor: default; }
    #welcome-widget .welcome-scroller .settings-pane .content .content-padding {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 1rem 5rem 1rem; }
      #welcome-widget .welcome-scroller .settings-pane .content .content-padding h1 {
        margin: 4rem 0 1.5rem; }
      #welcome-widget .welcome-scroller .settings-pane .content .content-padding .fb-connection-settings {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        #welcome-widget .welcome-scroller .settings-pane .content .content-padding .fb-connection-settings p {
          text-align: center;
          line-height: 1; }
      #welcome-widget .welcome-scroller .settings-pane .content .content-padding .version-info {
        text-align: center;
        line-height: 1; }
    #welcome-widget .welcome-scroller .center-pane {
      cursor: pointer; }
