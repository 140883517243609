@import './_include-media';
@import './common';


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

body, html {
	font-family: $font;
	// font-size: 18px;
}

// Modify text muted to comply with our styling
:global(.text-muted) {
	// !important necessary to override bootstrap's _text.scss:19 decl
	color: $content-color !important; //transparentize($content-color, .55) !important;
	filter: opacity(.55);
	line-height: 1.2;
}

:global(.btn) {
	color: transparentize($content-color, .2);
}

html {
	font-size: 30px;
}

@include media('retina2x') {
	html {
		font-size: 24px;

		@media (min-width: 768px) {
			font-size: 32px;
		}
	}
}

@include media('retina3x') {
	html {
		font-size: 24px;
	}
}

/**************************************************************************/

// Per https://css-tricks.com/snippets/css/turn-off-number-input-spinners/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/**************************************************************************/

input[type=color] {
	min-height: 2.35rem;
	padding: 0.25rem;
}

/**************************************************************************/


:global(.btn) {
	@include stylize-button($content-bg);

	&:global(.btn-success) {
		@include stylize-button($brand-primary);
	}
}

/**************************************************************************/

:global(a) {
	color: lighten($brand-primary, 20%);
	&:hover, &:active, &:focus {
		color: lighten($brand-primary, 5%);
	}
}